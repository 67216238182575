import React from "react";
import RouterLink from "next/link";

import {
	Link,
	LinkVariant,
	IconCogStroke,
	IconSwitch,
	Accordion,
	ButtonVariant,
	Skeleton,
} from "@jasperlabs/jux-next";

import AddInvestingEntityButton from "./AddInvestingEntityButton";

import InvestingEntityLabel from "./InvestingEntityLabel";

import { useInvestingEntitiesDropdownQueryQuery } from "./InvestingEntitiesDropdownMenu.generated";

const InvestingEntitiesMobileDropdownMenu = () => {
	const { data, loading } = useInvestingEntitiesDropdownQueryQuery();

	if (loading) {
		return (
			<div className="flex p-4 gap-4 h-[132px] items-center">
				<Skeleton.Circle className="w-10 h-10" />
				<div className="grid gap-2">
					<Skeleton.TextBody className="w-32 h-2" />
					<Skeleton.TextBody className="w-16 h-2" />
				</div>
			</div>
		);
	}

	const investingEntity = data?.account?.activeInvestingEntity;

	if (!investingEntity) {
		return (
			<div className="w-full p-4">
				<AddInvestingEntityButton
					variant={ButtonVariant.Secondary}
					className="w-full"
				/>
			</div>
		);
	}

	return (
		<div className="p-4 grid gap-4">
			<span className="text-overline-sm text-neutral-300">Investing as</span>
			<Accordion>
				<Accordion.Item
					hasBorder={false}
					titleContent={(open) => (
						<InvestingEntityLabel.AccordionTitle
							{...open}
							investingEntity={investingEntity}
						/>
					)}
					index={0}
				>
					<ul className="grid gap-4 pt-4">
						<li>
							<Link
								as={RouterLink}
								icon={<IconCogStroke className="text-[18px] text-black" />}
								variant={LinkVariant.Black}
								className="text-body-default flex w-full text-black"
								href="/investing-entities/details"
							>
								Investing entity settings
							</Link>
						</li>
						<li>
							<Link
								as={RouterLink}
								icon={<IconSwitch className="text-[18px] text-black" />}
								variant={LinkVariant.Black}
								className="text-body-default flex w-full text-black"
								href="/switch-investing-entities"
							>
								Switch / Add investing entity
							</Link>
						</li>
					</ul>
				</Accordion.Item>
			</Accordion>
		</div>
	);
};

export default InvestingEntitiesMobileDropdownMenu;
